import React from "react"
import PropTypes from "prop-types"
import Background from "./Background"
import Container from "@material-ui/core/Container"
import Backdrop from "@material-ui/core/Backdrop"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <Container style={{ maxWidth: "100vw", maxHeight: "100vh" }}>
      <Backdrop open />
      <main>{children}</main>
      <Background />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
